@if(dataLoader){
<div class="loader-wrapper">
  <span class="loader absolute-centered"></span>
</div>
}@else {

<div class="grid">
  <div class="wrapper-table">
    <cx-search-bar
      class="search-bar"
      [placeholder]="'OnDemandTJCreationSearchPlaceholder' | translate"
      [value]="selectedValue.asObservable()"
      (search)="search($event)"
    >
    </cx-search-bar>

    <gplus-table [data]="filterValues" [columns]="columns" [convertToList]="true">
      <ng-template gplus-cell-tmp let-item="item" let-id="id">
        @switch (id ) {
        <!--  -->
        @case ('patient') {
        <span *ngIf="item?.patient" class="patient-name">
          {{ item.patient | personName }}
        </span>
        } @case ('medication') {
        <span class="medicine-name">
          <ng-container *ngFor="let medicine of item?.medication?.medicines; let isLast = last">
            {{ medicine.display }}{{ isLast ? '' : ', ' }}</ng-container
          >
        </span>

        } @case ('dose') {

        <span class="medicine-name">
          <ng-container *ngFor="let medicine of item?.medication?.medicines; let isLast = last">
            {{ medicine.amount + medicine.unit }}{{ isLast ? '' : ', ' }}</ng-container
          >
        </span>

        } @case ('available') {

        <span> {{ item.medication.order }}/{{ item.medication.count }} </span>

        } @case (null) {
        <cx-chip state="active" [disabled]="!item?.available" (click)="selected(item.id)">
          {{ 'OnDemandCreateBtn' | translate }}
        </cx-chip>
        } }
      </ng-template>
    </gplus-table>
  </div>
</div>
}
