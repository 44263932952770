import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, ReplaySubject, Subject, take } from 'rxjs';
import { PrescriptionWithExtras } from '~/app/models/Extras';
import { SessionData } from '~/app/models/SessionData';
import { TherapyJobForm } from '~/app/models/TherapyJobForm';
import { TherapyJobService } from '~/app/services/therapy-job.service';
import { getPayloadFromForm } from '~/app/utils/getPayload';
import { DetailPageBodyComponent } from '../detail-page-body/detail-page-body.component';
import { DetailPageSidebarComponent } from '../detail-page-sidebar/detail-page-sidebar.component';
import { DetailPageStatus } from '../detail-page.component';

@Component({
  selector: 'gplus-detail-page-new',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DetailPageBodyComponent, DetailPageSidebarComponent],
  templateUrl: './detail-page-new.component.html',
  styleUrls: ['../common/page.scss']
})
export class DetailPageNewComponent implements OnChanges, OnInit {
  private patchSub: Subject<void> = new ReplaySubject();

  @Input() form: FormGroup<TherapyJobForm>;
  @Input() data: SessionData;
  @Input() pageStatus: DetailPageStatus;
  @Input() prescription: PrescriptionWithExtras;

  public submitLoader: boolean;

  constructor(private readonly therapyJobService: TherapyJobService, private readonly router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.prescription) {
      this.patchSub.next();
    }
  }

  ngOnInit(): void {
    this.patchSub.pipe(debounceTime(100)).subscribe(() => {
      this.patch();
      this.submitLoader = false;
    });

    this.initFields();
  }

  private initFields() {
    if (this.pageStatus === DetailPageStatus.CREATION_PRN) {
      const medicines = this.form.get('medication.medicines') as FormArray;
      medicines.at(0).get('medicine').disable();
      this.form.get('physician').disable();
      medicines
        .at(0)
        .get('customAmount')
        .setValidators([Validators.max(this.prescription?.medication?.medicines?.[0]?.amount), Validators.min(0)]);
    }
  }

  public submitChange() {
    if (this.form.valid) {
      this.submitLoader = true;

      this.therapyJobService
        .createTherapyJob(getPayloadFromForm(this.form))
        .pipe(take(1))
        .subscribe(() => {
          this.submitLoader = true;
          console.info('Therapy Job successfully created');

          this.form.reset();
          this.router.navigate(['/home']);
        });
    }
  }

  public resetChange() {
    if (this.prescription) {
      this.patchSub.next();
    } else {
      this.form.reset();
    }
  }

  private patch() {
    if (!this.prescription) {
      return;
    }

    const value = {
      volume: this.prescription.volume,
      medication: {
        ...this.prescription.medication.medicines,
        carrier: this.prescription.medication.carrier,
        medicines: this.prescription.medication.medicines.map((m) => {
          return {
            medicine: m,
            customAmount: m.amount,
            unit: m.unit
          };
        })
      },
      flowRate: this.prescription.flowRate,
      patient: this.prescription.patient,
      physician: this.data?.physicians?.find((p) => p.id === this.prescription.physician)
    };

    this.form.reset(value);
    this.form.markAsPristine();
  }
}
