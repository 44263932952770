<div class="wrapper">
  <gplus-top-bar class="header"></gplus-top-bar>
  <gplus-board class="board"></gplus-board>

  <!--  Mock FE to test monitor device interaction -->
  <!-- <div class="md-container">
        @if(loadingSimulation){

        <div class="loader-msm">
          <span class="loader inline"></span>
          Preparing simulation... Will take a few seconds
        </div>
        }@else { }
        <input type="number" [(ngModel)]="numberItems" style="width: 200px" placeholder="number of Jobs to simulate" />
        <button [disabled]="loadingSimulation" (click)="startSimulation()">Start Simulation</button>
      </div> -->
</div>
