import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { SseEventMessageKey, SseEventType, SseInfusion, SsePairing, SseState } from '../models/sse-event';
import { EventsService } from '../services/events.service';
import { NotificationService } from '../services/notification.service';
import { TherapyJobService } from '../services/therapy-job.service';

export class ComponentSSE {
  constructor(
    protected readonly eventsService: EventsService,
    protected readonly therapyJobService: TherapyJobService,
    protected readonly notificationService: NotificationService,
    protected readonly translateService: TranslateService
  ) {
    this.eventsService
      .subscribeToServerSentEvents()
      .pipe(takeUntilDestroyed())
      .subscribe((sseEvent: SseInfusion | SsePairing | SseState) => {
        if (!sseEvent || !sseEvent?.therapyJobId) {
          return;
        }

        if (sseEvent.type === SseEventType.JOB_UPDATE) {
          const infusionEvent: SseInfusion = sseEvent as SseInfusion;

          this.therapyJobService.patchTherapyJob(
            { latestEvent: infusionEvent, event: { type: SseEventType.JOB_UPDATE, timestamp: infusionEvent.ts } },
            infusionEvent.therapyJobId
          );
        }

        // When we receive an event of job start and finish
        // we want to reload all the therapy jobs to render them
        // in the proper column. This reload has debounce
        if (sseEvent.type === SseEventType.JOB_START || sseEvent.type === SseEventType.JOB_FINISH) {
          this.therapyJobService.reload.next('JOB END/START');
        }

        if (sseEvent.type === SseEventType.PAIRING) {
          const pairingEvent: SsePairing = sseEvent as SsePairing;

          this.therapyJobService.patchTherapyJob(
            {
              monitorDeviceId: pairingEvent.deviceId,
              pairingState: pairingEvent.pairingState,
              messageKey: pairingEvent?.messageKey as SseEventMessageKey,
              event: { type: SseEventType.PAIRING, timestamp: pairingEvent.ts }
            },
            pairingEvent.therapyJobId
          );
        }

        if (sseEvent.type === SseEventType.JOB_STATE) {
          const stateEvent: SseState = sseEvent as SseState;

          this.therapyJobService.patchTherapyJob(
            {
              dripChamberDetached: stateEvent.dripChamberDetached,
              event: { type: SseEventType.JOB_STATE, timestamp: stateEvent.ts }
            },
            stateEvent.therapyJobId
          );
        }
      });
  }
}
