import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { CxBadgeNotification, CxIcon } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, map } from 'rxjs';
import { HeaderService } from '~/app/services/header.service';
import { NotificationService } from '~/app/services/notification.service';
import { SidebarIds, SidebarService } from '~/app/services/sidebar.service';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { UserComponent } from '../user/user.component';

@Component({
  selector: 'gplus-header',
  standalone: true,
  imports: [
    SearchBarComponent,
    UserComponent,
    TranslateModule,
    CxIcon,
    CommonModule,
    RouterModule,
    CxBadgeNotification
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  public showSearch: boolean = true;
  public animateBadge = 'animate-badge';
  public numNotification: number;

  constructor(
    public readonly headerService: HeaderService,
    private readonly sidebarService: SidebarService,
    private readonly notificationService: NotificationService
  ) {
    toObservable(this.notificationService.notifications)
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntilDestroyed())
      .pipe(map((notificationsMap) => this.notificationService.getList(notificationsMap).length))
      .subscribe((length) => (this.numNotification = length));
  }

  public openMobileMenuClicked() {
    this.headerService.setMobileMenuBtn(!this.headerService.toggleMobileMenu());
  }

  public alarmClicked() {
    this.sidebarService.open(SidebarIds.ALARM_CENTER);
  }
}
