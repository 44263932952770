<h2 class="header-title">
  <cx-icon
    class="cx-icon mobile-drawer-toggle"
    [icon]="headerService.toggleMobileMenu() ? 'close' : 'menu'"
    size="md"
    (click)="openMobileMenuClicked()"
  ></cx-icon>

  <cx-icon
    *ngIf="headerService?.header()?.showBackBtn"
    class="cx-icon"
    routerLink="/home"
    icon="arrow_back"
    size="md"
  ></cx-icon>
  {{ headerService?.header()?.titleKey || 'AppTitle' | translate }}
</h2>
<gplus-search-bar *ngIf="headerService?.header()?.showSearch" class="header-search"></gplus-search-bar>

<div class="left-actions">
  <div class="alarm" (click)="alarmClicked()">
    <cx-icon class="header-icon" icon="notifications" size="sm"> </cx-icon>
    <cx-badge-notification *ngIf="numNotification" class="cx-badge-notification" [ngClass]="animateBadge" size="lg">{{
      numNotification
    }}</cx-badge-notification>
  </div>

  <gplus-user class="header-user"></gplus-user>
</div>
