<div
  [routerLink]="['/detail', { id: therapyJob.id }]"
  class="wrapper"
  [ngClass]="mostCriticalStatus"
  *ngIf="therapyJob"
  [id]="therapyJob.id"
>
  <div class="header">
    <h4 class="name">
      <span *ngIf="therapyJob?.patient" [innerHtml]="therapyJob?.patient | personName | highlight : highlightTerm">
      </span>
      <div class="id">{{ 'TherapyJobIdLabel' | translate }}: {{ therapyJob.id }}</div>
    </h4>
    <div class="header-left">
      <div class="badges">
        @if(therapyJob.prepared && therapyJob.status === therapyStatus.PRESCRIBED){

        <cx-badge-status type="secondary" status="success">{{ 'StatusPrepared' | translate }}</cx-badge-status>
        }

        <cx-badge-status type="secondary" *ngIf="therapyJob?.createdBy">{{ 'CustomJob' | translate }}</cx-badge-status>

        @if(mostCriticalStatus){
        <cx-badge-status [status]="mostCriticalStatus">
          <cx-icon icon="location_on" size="xs"></cx-icon> {{ therapyJob.patient?.careUnit?.room }} -
          {{ therapyJob.patient?.careUnit?.bed }}
        </cx-badge-status>

        } @else {

        <div class="care-unit">
          <cx-icon icon="location_on" size="xs"></cx-icon> {{ therapyJob.patient?.careUnit?.room }} -
          {{ therapyJob.patient?.careUnit?.bed }}
        </div>

        }
      </div>

      <cx-button
        class="cx-menu"
        slot="trigger"
        rounded="true"
        button-color="secondary"
        [disabled]="disableMenu"
        (click)="toggleMenu($event)"
        [attr.data-vef]="'card-menu-toggle'"
      >
        <cx-icon class="cx-menu-trigger" icon="more_vert" filled="true"></cx-icon>
      </cx-button>
    </div>
  </div>

  <div class="body">
    <div class="group b1 medicine">
      <cx-icon icon="medication" size="sm"></cx-icon>
      <span [innerHTML]="medicine?.first | highlight : highlightTerm"> </span>
      <span *ngIf="medicine?.more">{{ medicine.more }}</span>
    </div>
    <div class="group b1 device" [class.disabled]="therapyJob.pairingState !== pairingStatus.PAIRED">
      <gplus-icon-device [class.disabled]="therapyJob.pairingState !== pairingStatus.PAIRED"></gplus-icon-device>
      <span>{{ therapyJob.pairingState === pairingStatus.PAIRED ? therapyJob.monitorDeviceId : '-' }}</span>
    </div>
    <div class="group b1 flow-rate" [class.disabled]="!flowRate">
      <cx-icon icon="speed" size="sm"></cx-icon>
      <span>{{ flowRate || '-' }}</span>
    </div>
    <div class="group b1 time" [class.disabled]="!time">
      <cx-icon icon="alarm" size="sm"></cx-icon>

      <span class="time-label">
        <span>{{ time || '-' }} </span><span class="error-value">{{ timeError }}</span></span
      >
    </div>
  </div>

  <div class="footer" [class.has-annotations]="therapyJob.annotations && !showAnnotations">
    <!-- Annotations -->
    <gplus-annotations
      class="annotations"
      [class.show]="showAnnotations"
      [therapyJob]="therapyJob"
      [showAnnotations]="showAnnotations"
      (toggle)="toggleAnnotations($event)"
    ></gplus-annotations>

    <!-- Progress bar -->
    @if(therapyJob.status === therapyStatus.RUNNING && !tjNotificationMap?.[notificationId.DRIP_CHAMBER_DETACHED] &&
    !tjNotificationMap?.[notificationId.THERAPY_JOB_UNPAIRED_FROM_MD]){

    <gplus-infusion size="sm" [level]="mostCriticalStatus" [therapyJob]="therapyJob"></gplus-infusion>
    }

    <!-- Move to history button -->
    <cx-button
      *ngIf="therapyJob.status === therapyStatus.COMPLETED"
      button-color="secondary"
      variant="outlined"
      type="button"
      as="button"
      [disabled]="true"
      (click)="finishJob()"
    >
      <cx-icon icon="description"></cx-icon>
      {{ 'TherapyJobFinishJobBtn' | translate }}
    </cx-button>

    <!-- Monitor device -->
    <gplus-manage-device
      class="connect-selector"
      [therapyJob]="therapyJob"
      (click)="$event.stopPropagation()"
    ></gplus-manage-device>
  </div>

  <div class="notifications" *ngIf="tjNotificationMap">
    <div class="notification" *ngFor="let item of tjNotificationMap | keyvalue">
      <mat-icon fontIcon="warning"></mat-icon>
      {{ item.value.translationKey | translate }}
    </div>
  </div>

  <!-- Missing information -->
  <div *ngIf="missingInfo" class="missing-info">
    <div class="missing-info-inner">
      <mat-icon fontIcon="info"></mat-icon>
      {{ missingInfo.tooltip | translate }}
    </div>
  </div>
</div>
