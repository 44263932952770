import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { CxButton, CxList, CxListItem } from '@bbraun/cortex-angular';
import { CxSearchBarModule } from '@bbraun/cortex/search-bar';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, take } from 'rxjs';
import { Prescription } from '~/app/models/prescription';
import { Dialog, DialogService } from '~/app/services/dialog.service';
import { PrescriptionService } from '~/app/services/prescription.service';
import { SessionService } from '~/app/services/session.service';
import { TherapyJobService } from '~/app/services/therapy-job.service';
import { getPayloadFromPrescription } from '~/app/utils/getPayload';

@Component({
  selector: 'gplus-modal-on-demand',
  standalone: true,
  imports: [CommonModule, CxSearchBarModule, MatAutocompleteModule, TranslateModule, CxButton, CxList, CxListItem],
  templateUrl: './modal-on-demand.component.html',
  styleUrl: './modal-on-demand.component.scss'
})
export class ModalOnDemandComponent {
  private onDemandPrescriptions: Array<Prescription>;
  public filterValues: Array<Prescription>;
  public selectedValue: Subject<string> = new Subject<string>();

  public dataLoader: boolean;
  public submitLoader: boolean;

  public selected: Prescription;
  public dialog: Dialog;

  constructor(
    private readonly prescriptionService: PrescriptionService,
    protected readonly sessionService: SessionService,
    private readonly therapyJobService: TherapyJobService,
    private readonly router: Router,
    private readonly dialogService: DialogService
  ) {
    this.dialog = this.dialogService.dialog();

    this.dataLoader = true;
    this.prescriptionService
      .getOnDemandPrescriptions(this.sessionService.currentWard)
      .pipe(take(1))
      .subscribe((prescriptions) => {
        this.onDemandPrescriptions = prescriptions;
        this.filterValues = this.onDemandPrescriptions;
        this.dataLoader = false;
      });
  }

  public search(value: string) {
    this.filterValues = value
      ? this.onDemandPrescriptions?.filter((navItem) =>
          navItem?.patient?.lastName?.toLowerCase().startsWith(value.toLowerCase())
        )
      : this.onDemandPrescriptions;
  }

  public setSearchBarValue(event: string): void {
    this.selectedValue.next(event);
  }

  public select(prescription: Prescription) {
    this.selected = prescription;
  }

  public submit() {
    this.submitLoader = true;

    this.therapyJobService
      .createTherapyJob(getPayloadFromPrescription(this.selected))
      .pipe(take(1))
      .subscribe(() => {
        this.submitLoader = false;
        this.dialogService.close();
        // We clean the form, the instance is not destroyed by closing the dialog.
        this.router.navigate(['/home']);
      });
  }

  public modify() {
    this.router.navigate(['/create', { id: this.selected?.id }]);
    this.dialogService.close();
  }
  public cancel() {
    this.selected = null;
    this.dialogService.close();
  }
}
