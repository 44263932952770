import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, fromEvent, takeUntil } from 'rxjs';
import { TJHighLightMap } from '~/app/services/highlight.service';
import { TJNotificationsMap } from '~/app/services/notification.service';
import { OverlayService } from '~/app/services/overlay.service';
import { Column } from '../../models/ui/Column';
import { TherapyJobComponent } from '../therapy-job/therapy-job.component';

@Component({
  selector: 'gplus-board-column',
  standalone: true,
  imports: [
    // Modules
    CommonModule,
    TranslateModule,
    ScrollingModule,
    // Components
    TherapyJobComponent
  ],
  templateUrl: './board-column.component.html',
  styleUrl: './board-column.component.scss'
})
export class BoardColumnComponent implements AfterViewInit, OnDestroy {
  @Input() column: Column;
  @Input() tJNotificationsMap: TJNotificationsMap;
  @Input() tjHighlightMap: TJHighLightMap;
  @Input() ticker: number;
  @Input() highlightTerm: string;

  @ViewChild('wrapper') wrapper: ElementRef;
  private destroy: Subject<void> = new Subject();

  constructor(private readonly overlayService: OverlayService) {}
  ngAfterViewInit(): void {
    fromEvent(this.wrapper.nativeElement, 'scroll')
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.overlayService.close();
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public tJTrackBy(index, therapyJob) {
    return therapyJob?.id;
  }
}
