<h5 class="title">
  {{ 'Columns' + column?.id | translate }}
  <span class="number"> ({{ column?.therapyJobs.length }}) </span>
</h5>
<div #wrapper class="wrapper">
  <gplus-therapy-job
    *ngFor="let therapyJob of column?.therapyJobs; trackBy: tJTrackBy"
    [id]="therapyJob.id"
    [therapyJob]="therapyJob"
    [tjNotificationMap]="tJNotificationsMap?.[therapyJob.id] || {}"
    [tjHighlightMap]="tjHighlightMap"
    [ticker]="ticker"
    [highlightTerm]="highlightTerm"
  ></gplus-therapy-job>
</div>
