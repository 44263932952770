import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, catchError, interval, map, of, startWith, switchMap, takeUntil } from 'rxjs';
import { TherapyJobService } from './therapy-job.service';

export enum Status {
  UP = 'UP',
  DOWN = 'DOWN',
  ERROR = 'ERROR'
}

const SNACKBAR_DISCONNECTION_ID = 'connectionLostMessage';

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  private destroy: Subject<void> = new Subject();
  public status: {
    id: Status;
    translationKey: string;
  };

  constructor(
    private readonly httpClient: HttpClient,
    private readonly translateService: TranslateService,
    private readonly therapyJobService: TherapyJobService
  ) {}

  private initPolling() {
    // Polling
    interval(10000)
      .pipe(
        takeUntil(this.destroy),
        startWith(Status.UP),
        switchMap(() => {
          return this.httpClient.get('q/health/live').pipe(
            map((res: any) => {
              if (res.status === Status.DOWN) {
                console.error('System is DOWN!');
              }

              return res?.status;
            }),
            catchError((err) => {
              console.error(`Error coming from 'q/health/live'`, err);

              return of(Status.ERROR);
            })
          );
        })
      )
      .subscribe((res) => {
        if (res === Status.ERROR || res === Status.DOWN) {
          this.status = {
            id: res,
            translationKey: 'SystemStatusDisconnected'
          };

          this.showErrorSnackbar();
          console.error(`Error coming from 'q/health/live'`);
        } else {
          // If the status was previously down but has recovered
          if (this.status?.id === Status.ERROR || this.status?.id === Status.DOWN) {
            // we update the therapy jobs
            this.therapyJobService.reload.next('Health polling');
          }

          this.status = {
            id: res,
            translationKey: 'SystemStatusConnected'
          };
        }
      });
  }

  public destroyPolling() {
    this.destroy.next();
    this.destroy.complete();
  }

  private showErrorSnackbar() {
    setTimeout(() => {
      // If the snackbar is already rendered we skip this
      if (document.querySelector('cx-snackbar')?.shadowRoot.querySelector(`#${SNACKBAR_DISCONNECTION_ID}`)) {
        return;
      }
      // We notify to the user that the system is down or there is an error with the endpoint
      const snackbarConfig = {
        type: 'error',
        message: this.translateService.instant('ConnectionLostMessage'),
        enableCloseButton: true,
        delay: 20000,
        id: SNACKBAR_DISCONNECTION_ID
      };
      window.dispatchEvent(new CustomEvent('cx-snackbar-open', { detail: snackbarConfig }));
    }, 100);
  }
}
