import { TJNotificationMap } from '../services/notification.service';
import { NotificationLevel } from '../enums/NotificationLevel';

// If one of the notification is an error we keep all teh notification marked as error
export const getCriticalType = (tjNotificationMap: TJNotificationMap) => {
  return Object.keys(tjNotificationMap).reduce<NotificationLevel>((acc, key) => {
    const notification = tjNotificationMap[key];
    if (acc === NotificationLevel.ERROR) {
      return acc;
    }

    return notification.level;
  }, null);
};
