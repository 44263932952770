@if(!loginDisplay){
<cx-heart-beat [loading]="true" width="600px"></cx-heart-beat>
} @else {

<gplus-header></gplus-header>

<div class="content-wrapper">
  <cx-navigation-rail class="cx-navigation-rail">
    <div class="item-with-badge">
      <cx-navigation-rail-item
        routerLinkActive="active"
        routerLink="/home"
        [name]="'TherapyJobsDashboard' | translate"
        icon="dashboard"
        (click)="linkClicked()"
      >
      </cx-navigation-rail-item>
    </div>
    <cx-navigation-rail-item
      routerLinkActive="active"
      routerLink="/prn"
      [name]="'OnDemandPrescriptions' | translate"
      icon="view_list"
      (click)="linkClicked()"
    ></cx-navigation-rail-item>
  </cx-navigation-rail>

  @if(headerService.toggleMobileMenu()){
  <gplus-mobile-drawer class="gplus-mobile-drawer"> </gplus-mobile-drawer>
  }

  <div class="router-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>
<gplus-modal-container [dialog]="dialogService.dialog()"> </gplus-modal-container>
<gplus-overlay [overlay]="overlayService.overlay()"> </gplus-overlay>
<gplus-sidebar-container [sidebar]="sidebarService.sidebar()"></gplus-sidebar-container>

}
