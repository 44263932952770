import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CxBanner, CxButton, CxDivider, CxIcon } from '@bbraun/cortex-angular';
import { CxNumberInputModule } from '@bbraun/cortex/number-input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { CustomShadowCssDirective } from '~/app/directives/CustomShadowCss';
import { MedicineUnit } from '~/app/enums/MedicineUnits';
import { CarrierSolution } from '~/app/models/generated/carrierSolution';
import { Medicine } from '~/app/models/generated/medicine';
import { Patient } from '~/app/models/generated/patient';
import { SessionData } from '~/app/models/SessionData';
import { TherapyJob } from '~/app/models/TherapyJob';
import { TherapyJobForm } from '~/app/models/TherapyJobForm';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { MedicineService } from '~/app/services/medicine.service';
import { TJNotificationMap } from '~/app/services/notification.service';
import { MedicineValidator } from '~/app/utils/medicineValidator';
import { change } from '~/app/utils/valueChanges';
import { DetailPageHeaderComponent } from '../detail-page-header/detail-page-header.component';
import { DetailPageStatus } from '../detail-page.component';

@Component({
  selector: 'gplus-detail-page-body',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    CxNumberInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    FormsModule,
    CxButton,
    CxIcon,
    CxDivider,
    CxBanner,
    DetailPageHeaderComponent,
    PersonNamePipe,
    CustomShadowCssDirective
  ],
  templateUrl: './detail-page-body.component.html',
  styleUrl: './detail-page-body.component.scss'
})
export class DetailPageBodyComponent implements OnInit {
  public detailPageStatus = DetailPageStatus;
  @Input() pageStatus: DetailPageStatus;
  @Input() form: FormGroup<TherapyJobForm>;
  @Input() data: SessionData;
  @Input() therapyJob: TherapyJob;
  @Input() tjNotificationMap: TJNotificationMap;

  @Output() submitChange: EventEmitter<void> = new EventEmitter();
  @Output() resetChange: EventEmitter<void> = new EventEmitter();

  // Mode this out!
  public patient: Patient;

  private allMedicines: Array<Medicine>;

  public filteredAllMedicines: Array<Observable<Array<Medicine>>> = [];
  public filteredCarrier: Observable<Array<CarrierSolution>>;
  public filteredPatient: Observable<Array<Patient>>;

  public units = Object.values(MedicineUnit);

  public submitLoader: boolean;
  public dataLoader: boolean;

  get medicines() {
    return this.form.get('medication.medicines') as FormArray;
  }

  constructor(private readonly medicineService: MedicineService, private readonly translate: TranslateService) {}

  ngOnInit(): void {
    this.initFilteredData();

    if (this.form) {
      this.form.get('patient').valueChanges.subscribe((res) => {
        this.patient = res;
      });
    }
  }

  private initFilteredData() {
    if (!this.data) {
      return;
    }

    const { medicines, carriers, patients } = this.data;
    const noCarrierPlaceholder = {
      description: this.translate.instant('FormTJCreationReadyToAdminister')
    };

    this.allMedicines = medicines;

    this.filteredCarrier = change(
      this.form.get('medication.carrier'),
      [noCarrierPlaceholder, ...carriers],
      'description'
    );

    this.filteredPatient = change(this.form.get('patient'), patients, 'lastName');

    this.filteredAllMedicines = Array((this.form.get('medication.medicines') as FormArray).length)
      .fill(null)
      .map((_, i) => change(this.medicines.at(i).get('medicine'), this.allMedicines, 'display'));
  }

  public addMedicine() {
    this.medicines.push(
      new FormGroup({
        medicine: new FormControl(null, [], [MedicineValidator.createValidator(this.medicineService)]),
        customAmount: new FormControl(null, [Validators.min(0)]),
        unit: new FormControl(null)
      })
    );

    this.filteredAllMedicines.push(
      change(this.medicines.at(this.medicines.length - 1).get('medicine'), this.allMedicines, 'display')
    );
  }

  public removeMedicine(index: number) {
    this.medicines.removeAt(index);
    this.filteredAllMedicines.splice(index, 1);
  }

  public carrierName(carrier: CarrierSolution): string {
    if (!carrier) {
      return '';
    }
    return `${carrier.description}`;
  }

  public medicineName(medicine: Medicine): string {
    if (!medicine) {
      return '';
    }
    return `${medicine.display}`;
  }

  public displayPersonName(person: { lastName: string; firstName: string }): string {
    if (!person) {
      return '';
    }

    const personNamePipe = new PersonNamePipe();
    return personNamePipe.transform(person);
  }
}
