import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CxBadgeStatus, CxButton, CxIcon } from '@bbraun/cortex-angular';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { NotificationLevel } from '~/app/enums/NotificationLevel';
import { PairingStatus } from '~/app/enums/PairingStatus';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { Employee } from '~/app/models/generated/employee';
import { SessionData } from '~/app/models/SessionData';
import { TherapyJob } from '~/app/models/TherapyJob';
import { TherapyJobForm } from '~/app/models/TherapyJobForm';
import { DurationFormatPipe, TimeUnit } from '~/app/pipes/DurationFormat';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { ProgressPipe } from '~/app/pipes/Progress';
import { TJNotificationMap } from '~/app/services/notification.service';
import { setTimeError } from '~/app/utils/DelayTime';
import { getCriticalType } from '~/app/utils/getCriticalNotificationType';
import { change } from '~/app/utils/valueChanges';
import { CxCheckboxExtendedComponent } from '../../../utils/CxCheckboxExtended';
import { InfusionComponent } from '../../infusion/infusion.component';
import { ManageDeviceComponent } from '../../manage-device/manage-device.component';
import { DetailPageStatus } from '../detail-page.component';
import { NotificationId } from '~/app/enums/NotificationId';

@Component({
  selector: 'gplus-detail-page-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    CxButton,
    CxIcon,
    CxCheckboxExtendedComponent,
    ManageDeviceComponent,
    CxBadgeStatus,
    DurationFormatPipe,
    ProgressPipe,
    PersonNamePipe,
    InfusionComponent
  ],
  templateUrl: './detail-page-sidebar.component.html',
  styleUrl: './detail-page-sidebar.component.scss',
  providers: [TranslatePipe, DurationFormatPipe]
})
export class DetailPageSidebarComponent implements OnInit, OnChanges {
  // Public enums
  public detailPageStatus = DetailPageStatus;
  public therapyStatus = TherapyJobStatus;
  public pairingStatus = PairingStatus;
  public timeUnit = TimeUnit;
  public notificationId = NotificationId;

  @Input() pageStatus: DetailPageStatus;

  // Loaders
  public preparedLoader: boolean;

  public filteredPhysician: Observable<Array<Employee>>;

  public prepared: boolean;

  public timeError: string;

  @Input() form: FormGroup<TherapyJobForm>;
  @Input() therapyJob: TherapyJob;
  @Input() data: SessionData;
  @Input() ticker: number;
  @Input() tjNotificationMap: TJNotificationMap;

  public mostCriticalStatus: NotificationLevel;

  constructor(private readonly translatePipe: TranslatePipe, private readonly durationPipe: DurationFormatPipe) {}

  ngOnInit(): void {
    if (this.data) {
      this.filteredPhysician = change(this.form.get('physician'), this.data.physicians, 'lastName');
    }

    if (this.form) {
      this.form.get('prepared').valueChanges.subscribe((res) => {
        this.prepared = res;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ticker) {
      this.setTimeError(this.therapyJob);
    }

    if (changes.tjNotificationMap) {
      this.setNotificationStatus();
    }
  }

  public displayPersonName(person: { lastName: string; firstName: string }): string {
    if (!person) {
      return '';
    }

    const personNamePipe = new PersonNamePipe();
    return personNamePipe.transform(person);
  }

  private setTimeError(therapyJob: TherapyJob): void {
    this.timeError = setTimeError(therapyJob.startTime.planned, this.durationPipe, this.translatePipe);
  }

  private setNotificationStatus() {
    // If one of the notification is an error we keep it as error
    this.mostCriticalStatus = getCriticalType(this.tjNotificationMap);
  }
}
