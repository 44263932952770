@if(therapyJobService.loadingTJs){
<div class="loader"></div>
}
<div class="board-wrapper" [class.loading]="therapyJobService.loadingTJs">
  <div class="tabs">
    @for (column of columns(); track column.id) {

    <cx-chip
      customShadowCss
      styles=".chip.radio{padding: 0 0.5rem!important;line-height:1!important}"
      (click)="selectChip(column.id)"
      [state]="column.id === currentColumn ? 'active' : null"
    >
      {{ 'Columns' + column.id | translate }} ({{ column?.therapyJobs.length }})</cx-chip
    >
    }
  </div>
  @for (column of columns(); track column.id) {

  <gplus-board-column
    class="board-column"
    [attr.data-vef]="'column-title-' + column?.id"
    [class.active]="column.id === currentColumn"
    [id]="column.id"
    [column]="column"
    [tjHighlightMap]="highlightService.highlightMap()"
    [tJNotificationsMap]="notificationsMap"
    [ticker]="ticker"
    [highlightTerm]="highlightTerm()"
  ></gplus-board-column>
  }
</div>
