<div *ngIf="_showAnnotations || showIcon" class="b1 header">
  <span *ngIf="_showAnnotations">{{ 'Annotations' | translate }}</span>
  <div *ngIf="showIcon" class="trigger">
    @if(_showAnnotations){

    <cx-button class="cx-button" rounded="true" button-color="secondary" (click)="cancel($event)">
      <cx-icon icon="close" [filled]="false"></cx-icon>
    </cx-button>

    } @else {
    <cx-tooltip [content]="'ThisTJHasClinicalNotes' | translate" placement="bottom">
      <cx-button class="cx-button" rounded="true" button-color="secondary" (click)="toggleAnnotations($event)">
        <cx-icon [attr.data-vef]="'card-annotations-buttonEdit'" icon="clinical_notes" [filled]="false"></cx-icon>
      </cx-button>
    </cx-tooltip>
    }
  </div>
</div>

<div *ngIf="_showAnnotations" class="editor">
  <textarea
    #textArea
    [attr.data-vef]="'card-annotations-textarea'"
    [disabled]="submitLoader"
    [(ngModel)]="model"
    (click)="$event.stopPropagation()"
    (ngModelChange)="change()"
  ></textarea>
  <div *ngIf="isDirty || !_therapyJob?.annotations" class="buttons">
    <cx-button
      variant="ghost"
      button-color="secondary"
      (click)="cancel($event)"
      [attr.data-vef]="'card-annotations-buttonCancel'"
    >
      {{ 'AnnotationsCancelBtn' | translate }}
    </cx-button>
    <cx-button
      variant="ghost"
      button-color="accent"
      (click)="save($event)"
      [attr.data-vef]="'card-annotations-buttonSubmit'"
    >
      {{ _therapyJob?.annotations ? ('AnnotationsEditBtn' | translate) : ('AnnotationsSaveBtn' | translate) }}
      <span *ngIf="submitLoader" class="loader inline accent"></span>
    </cx-button>
  </div>
</div>
