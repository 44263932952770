<div class="wrapper" (click)="$event.stopPropagation()">
  @if(loading){
  <div class="loader"></div>

  } @else { @if(notifications?.length){ @for( notification of notifications; track notification.id){
  <gplus-snackbar
    class="snack"
    [ngClass]="notification.level"
    [type]="notification.level"
    [icon]="'warning'"
    [title]="notification.translationKey | translate"
    [text]="notification?.therapyJob?.patient | personName"
    [timestamp]="notification?.timestamp | date : 'h:mm a'"
    (click)="highlight(notification?.therapyJobId)"
  >
    <cx-badge-status class="cx-badge-status" [badge] [status]="notification.level">
      <cx-icon icon="location_on" size="xs"></cx-icon> {{ notification?.therapyJob?.patient.careUnit.room }} -
      {{ notification?.therapyJob?.patient.careUnit.bed }}
    </cx-badge-status>
  </gplus-snackbar>

  } }@else {
  {{ 'AlarmCenterNoAlarms' | translate }}
  } }
</div>
