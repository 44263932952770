import { CommonModule, DatePipe, Location } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, map } from 'rxjs';
import { TherapyJob } from '~/app/models/TherapyJob';
import { TherapyJobNotification } from '~/app/models/TherapyJobNotification';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { HighlightService } from '~/app/services/highlight.service';
import { NotificationService } from '~/app/services/notification.service';
import { SidebarService } from '~/app/services/sidebar.service';
import { TherapyJobService } from '~/app/services/therapy-job.service';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'gplus-alarm-center',
  standalone: true,
  imports: [SnackbarComponent, TranslateModule, PersonNamePipe, DatePipe, CommonModule],
  templateUrl: './alarm-center.component.html',
  styleUrl: './alarm-center.component.scss'
})
export class AlarmCenterComponent {
  public notifications: Array<TherapyJobNotification & { therapyJob: TherapyJob }>;
  public loading: boolean;

  constructor(
    private readonly notificationService: NotificationService,
    private readonly therapyJobService: TherapyJobService,
    private readonly highlightService: HighlightService,
    private readonly sidebarService: SidebarService,
    private readonly location: Location,
    private readonly router: Router
  ) {
    this.loading = true;
    toObservable(this.notificationService.notifications)
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntilDestroyed())
      .pipe(
        map((notificationsMap) => {
          this.loading = true;
          return this.notificationService.getList(notificationsMap).filter((n) => n.showAlarmCenter);
        })
      )
      .subscribe((list) => {
        this.setNotifications(list);
        this.loading = false;
      });
  }

  public highlight(therapyJobId: string) {
    this.sidebarService.close();

    if (!this.location.path().endsWith('home')) {
      this.router.navigate(['/home']);
    }

    this.highlightService.highlightTJ(therapyJobId);
  }

  private setNotifications(list: Array<TherapyJobNotification>) {
    this.notifications = list.map((notification) => {
      return {
        therapyJob: this.therapyJobService.therapyJobs()?.find((tj) => tj.id === notification.therapyJobId),
        ...notification
      };
    });
  }
}
