import { CommonModule, DatePipe } from '@angular/common';
import { Component, ElementRef, HostBinding, Input, OnChanges, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { CxBadgeStatus, CxButton, CxIcon, CxMenu, CxMenuItem } from '@bbraun/cortex-angular';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { take } from 'rxjs';
import { enterLeave } from '~/app/animations/enterLeave';
import { IconDeviceComponent } from '~/app/custom-icons/icon-device/icon-device.component';
import { NotificationId } from '~/app/enums/NotificationId';
import { PairingStatus } from '~/app/enums/PairingStatus';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { HighlightPipe } from '~/app/pipes/Hightlight';
import { PersonNamePipe } from '~/app/pipes/PersonName';
import { ProgressPipe } from '~/app/pipes/Progress';
import { HighlightService, TJHighLightMap } from '~/app/services/highlight.service';
import { OverlayService } from '~/app/services/overlay.service';
import { TherapyJobService } from '~/app/services/therapy-job.service';
import { ComponentAlert } from '~/app/utils/ComponentAlert';
import { DurationFormatPipe, TimeUnit } from '../../pipes/DurationFormat';
import { AnnotationsComponent } from '../annotations/annotations.component';
import { InfusionComponent } from '../infusion/infusion.component';
import { ManageDeviceComponent } from '../manage-device/manage-device.component';
import { MenuComponent, MenuItem } from '../menu/menu.component';

const HIGHLIGHT_DURATION = 3000;
const HIGHLIGHT_ITERATION = 3;
const HIGHLIGHT_DELAY = 100;

const TIME_FORMAT = 'short';
const FLOW_RATE_UNIT_KEY = 'UnitMlHr';

@Component({
  selector: 'gplus-therapy-job',
  standalone: true,
  imports: [
    // Modules
    CommonModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    RouterModule,
    CxButton,
    CxIcon,
    CxMenu,
    CxMenuItem,
    CxBadgeStatus,

    // Pipes
    DurationFormatPipe,
    ProgressPipe,
    HighlightPipe,
    PersonNamePipe,

    // Components
    ManageDeviceComponent,
    AnnotationsComponent,
    InfusionComponent,

    // Custom icons
    IconDeviceComponent
  ],
  templateUrl: './therapy-job.component.html',
  styleUrl: './therapy-job.component.scss',
  animations: [enterLeave],
  providers: [TranslatePipe, DatePipe, DurationFormatPipe]
})
export class TherapyJobComponent extends ComponentAlert implements OnChanges, OnDestroy {
  @Input() highlightTerm: string;

  public medicine: { first: string; more: string };
  public flowRate: string;

  public time: string;
  public displayName: string;
  public showAnnotations: boolean;
  public disableMenu: boolean;

  /*
    Animations
  */
  @HostBinding('@enterLeave') animate = true;

  @HostBinding('style.--highlight-duration') highlightDurationVar = `${HIGHLIGHT_DURATION}ms`;
  @HostBinding('style.--highlight-iteration') iterationDurationVar = HIGHLIGHT_ITERATION;
  @HostBinding('style.--highlight-delay') delayDurationVar = `${HIGHLIGHT_DELAY}ms`;
  @HostBinding('class.highlight-job') highlight: boolean;

  @Input() set tjHighlightMap(value: TJHighLightMap) {
    if (this.therapyJob.id && value[this.therapyJob.id]) {
      this.highlight = true;

      this.elm.nativeElement.scrollIntoView({ behavior: 'smooth' });

      // After the animation is complete we want to clean
      setTimeout(() => {
        this.highlight = false;
        this.highlightService.cleanHighlightTJ(this.therapyJob.id);
      }, HIGHLIGHT_DURATION * HIGHLIGHT_ITERATION + HIGHLIGHT_DELAY);
    }
  }

  // Make public enums
  public therapyStatus = TherapyJobStatus;
  public notificationId = NotificationId;
  public pairingStatus = PairingStatus;
  public timeUnit = TimeUnit;

  private menuItems: Array<MenuItem>;

  constructor(
    private readonly highlightService: HighlightService,
    private readonly datePipe: DatePipe,
    private readonly therapyJobService: TherapyJobService,
    private readonly overlayService: OverlayService,
    private readonly elm: ElementRef,
    override readonly durationPipe: DurationFormatPipe,
    override readonly translatePipe: TranslatePipe
  ) {
    super(durationPipe, translatePipe);

    this.tjUpdate.pipe(takeUntilDestroyed()).subscribe(() => {
      this.setMedicine();
      this.setTime(this.therapyJob?.status);
      this.setFlowRate(this.therapyJob?.status);
      this.setMenu();
    });
  }

  ngOnDestroy(): void {
    if (this.therapyJob) {
      this.highlightService.cleanHighlightTJ(this.therapyJob.id);
    }
  }

  private setMedicine() {
    const medicines = this.therapyJob?.medication?.medicines;
    if (!medicines?.length) {
      this.medicine = {
        first: '-',
        more: null
      };
      return;
    }

    this.medicine = {
      first: medicines?.[0]?.display || '-',
      more: medicines.length > 1 ? `+ ${medicines.length - 1}` : null
    };
  }

  private setFlowRate(status: TherapyJobStatus) {
    switch (status) {
      case TherapyJobStatus.PRESCRIBED:
        const planned = this.therapyJob?.flowRate?.planned;
        this.flowRate = planned ? `${planned} ${this.translatePipe.transform(FLOW_RATE_UNIT_KEY)}` : null;

        break;

      case TherapyJobStatus.RUNNING:
        this.flowRate = `${this.therapyJob?.latestEvent?.currentFlowRate || 0} ${this.translatePipe.transform(
          FLOW_RATE_UNIT_KEY
        )}`;
        break;

      case TherapyJobStatus.COMPLETED:
        this.flowRate = `0 ${this.translatePipe.transform(FLOW_RATE_UNIT_KEY)}`;
        break;
    }
  }

  private setTime(status: TherapyJobStatus) {
    switch (status) {
      case TherapyJobStatus.PRESCRIBED:
        const planned = this.therapyJob?.startTime?.planned;
        this.time = planned ? `${this.datePipe.transform(planned, TIME_FORMAT)}` : null;
        break;

      case TherapyJobStatus.RUNNING:
        const real = this.therapyJob?.startTime?.real;
        this.time = real
          ? `${this.translatePipe.transform('LabelStartTime')} ${this.datePipe.transform(real, TIME_FORMAT)}`
          : null;
        break;

      case TherapyJobStatus.COMPLETED:
        const endTime = this.therapyJob?.endTime;
        this.time = endTime
          ? `${this.translatePipe.transform('LabelEndTime')} ${this.datePipe.transform(endTime, TIME_FORMAT)}`
          : null;
        break;
    }
  }

  private setMenu() {
    this.menuItems = [
      this.therapyJob.status === TherapyJobStatus.PRESCRIBED
        ? {
            vefId: 'card-menu-optionEditPrepared',
            label: this.therapyJob.prepared
              ? this.translatePipe.transform('ActionMarkAsNotPrepared')
              : this.translatePipe.transform('ActionMarkAsPrepared'),
            icon: 'bookmark',
            disabled: false,
            onClick: () => {
              this.togglePrepared();
            }
          }
        : null,
      {
        vefId: 'card-menu-optionAddAnnotation',
        label: this.translatePipe.transform('ActionAddNotes'),
        icon: 'add_notes',
        disabled: false,
        onClick: () => {
          this.toggleAnnotations(true);
        }
      },
      {
        vefId: 'card-menu-optionEditTherapyJob',
        label: this.translatePipe.transform('ActionModify'),
        icon: 'edit',
        disabled: true,
        onClick: () => {}
      }
    ].filter((d) => !!d);
  }

  public finishJob() {
    console.log('Implement finish job');
  }

  public togglePrepared() {
    this.therapyJob = { ...this.therapyJob, prepared: !this.therapyJob.prepared };

    this.disableMenu = true;
    this.therapyJobService
      .updateTherapyJob(this.therapyJob)
      .pipe(take(1))
      .subscribe(() => {
        this.disableMenu = false;
      });
    this.setMenu();
  }

  public toggleAnnotations(value) {
    this.showAnnotations = value;
  }

  public toggleMenu(event) {
    event.stopPropagation();

    this.overlayService.toggle({
      id: this.therapyJob.id,
      component: MenuComponent,
      inputs: { items: this.menuItems, triggerElm: event.target }
    });
  }
}
