import { CommonModule } from '@angular/common';
import { Component, Signal, computed } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { CxButton, CxChip } from '@bbraun/cortex-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, interval } from 'rxjs';
import { CustomShadowCssDirective } from '~/app/directives/CustomShadowCss';
import { TherapyJobStatus } from '~/app/enums/TherapyJobStatus';
import { EventsService } from '~/app/services/events.service';
import { FilterService } from '~/app/services/filter.service';
import { HighlightService } from '~/app/services/highlight.service';
import { NotificationService, TJNotificationsMap } from '~/app/services/notification.service';
import { ComponentSSE } from '~/app/utils/ComponentSSE';
import { getColumns } from '~/app/utils/getColumns';
import { Column } from '../../models/ui/Column';
import { TherapyJobService } from '../../services/therapy-job.service';
import { BoardColumnComponent } from '../board-column/board-column.component';

@Component({
  selector: 'gplus-board',
  standalone: true,
  imports: [
    // Modules
    CommonModule,
    TranslateModule,
    CxButton,
    CxChip,
    // Components
    BoardColumnComponent,
    CustomShadowCssDirective
  ],
  templateUrl: './board.component.html',
  styleUrl: './board.component.scss'
})
export class BoardComponent extends ComponentSSE {
  public columns: Signal<Array<Column>>;
  public notificationsMap: TJNotificationsMap;
  public ticker: number;
  public highlightTerm: Signal<string>;

  // Only for responsive
  public currentColumn: string = TherapyJobStatus.PRESCRIBED;

  constructor(
    public readonly therapyJobNotificationService: NotificationService,
    public readonly highlightService: HighlightService,
    private readonly filterService: FilterService,
    public override therapyJobService: TherapyJobService,
    protected override eventsService: EventsService,
    protected override notificationService: NotificationService,
    protected override translateService: TranslateService
  ) {
    super(eventsService, therapyJobService, notificationService, translateService);
    interval(5000)
      .pipe(takeUntilDestroyed())
      .subscribe((res) => {
        this.ticker = res;
      });

    this.columns = computed(() => getColumns(this.therapyJobService.therapyJobs()));
    this.highlightTerm = computed(() => this.filterService.filters().queries.find((q) => q.on === 'search')?.bindValue);

    toObservable(this.notificationService.notifications)
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntilDestroyed())
      .subscribe((map) => {
        this.notificationsMap = JSON.parse(JSON.stringify(map));
      });
  }

  public selectChip(columnId: string) {
    this.currentColumn = columnId;
  }
}
