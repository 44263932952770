import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { NotificationId } from '../enums/NotificationId';
import { NotificationKey } from '../enums/NotificationKey';
import { NotificationLevel } from '../enums/NotificationLevel';
import { PairingStatus } from '../enums/PairingStatus';
import { TherapyJobStatus } from '../enums/TherapyJobStatus';
import { TherapyJobExtended } from '../models/TherapyJob';
import { TherapyJobNotification } from '../models/TherapyJobNotification';
import { TherapyJobService } from './therapy-job.service';

export interface TJNotificationMap {
  [notificationId: string]: TherapyJobNotification;
}

export interface TJNotificationsMap {
  [therapyJobId: string]: TJNotificationMap;
}
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly notificationsSignal: WritableSignal<TJNotificationsMap> = signal({});
  public notifications: Signal<TJNotificationsMap> = this.notificationsSignal.asReadonly();

  constructor(private readonly therapyJobService: TherapyJobService) {
    toObservable(this.therapyJobService.therapyJobs)
      .pipe(debounceTime(1000), distinctUntilChanged(), takeUntilDestroyed())
      .subscribe((tjs) => {
        tjs.forEach((tj) => {
          this.setDripChamberNotification(tj);
          this.setUnpairOnRunning(tj);
        });
      });
  }

  public setUnpairOnRunning(therapyJob: TherapyJobExtended) {
    if (!therapyJob) {
      return;
    }

    if (
      therapyJob.pairingState !== PairingStatus.UN_PAIRED &&
      therapyJob.pairingState !== null &&
      this.notifications()?.[therapyJob.id]?.[NotificationId.THERAPY_JOB_UNPAIRED_FROM_MD]
    ) {
      this.notificationsSignal.update((prevValue) => {
        const tjNotifications = prevValue[therapyJob.id] || {};
        delete tjNotifications[NotificationId.THERAPY_JOB_UNPAIRED_FROM_MD];

        return {
          ...prevValue,
          [therapyJob.id]: tjNotifications
        };
      });
    } else if (
      (therapyJob.pairingState === PairingStatus.UN_PAIRED || therapyJob.pairingState === null) &&
      therapyJob.status === TherapyJobStatus.RUNNING &&
      !this.notifications()?.[therapyJob.id]?.[NotificationId.THERAPY_JOB_UNPAIRED_FROM_MD]
    ) {
      const newNotification: TherapyJobNotification = {
        id: `${NotificationId.THERAPY_JOB_UNPAIRED_FROM_MD}-${therapyJob.id}`,
        type: NotificationId.THERAPY_JOB_UNPAIRED_FROM_MD,
        timestamp: therapyJob?.event?.timestamp,
        therapyJobId: therapyJob.id,
        translationKey: NotificationKey.THERAPY_JOB_UNPAIRED_FROM_MD,
        hintKey: NotificationKey.THERAPY_JOB_UNPAIRED_FROM_MD_HINT,
        level: NotificationLevel.ERROR,
        showAlarmCenter: true
      };

      this.notificationsSignal.update((prevValue) => {
        const tjNotifications = prevValue[therapyJob.id] || {};
        tjNotifications[NotificationId.THERAPY_JOB_UNPAIRED_FROM_MD] = newNotification;

        return {
          ...prevValue,
          [therapyJob.id]: tjNotifications
        };
      });
    }
  }

  public setDripChamberNotification(therapyJob: TherapyJobExtended) {
    if (!therapyJob) {
      return;
    }

    const value = therapyJob.dripChamberDetached;

    if (!value && this.notifications()?.[therapyJob.id]?.[NotificationId.DRIP_CHAMBER_DETACHED]) {
      this.notificationsSignal.update((prevValue) => {
        const tjNotifications = prevValue[therapyJob.id] || {};
        delete tjNotifications[NotificationId.DRIP_CHAMBER_DETACHED];

        return {
          ...prevValue,
          [therapyJob.id]: tjNotifications
        };
      });
    } else if (value && !this.notifications()?.[therapyJob.id]?.[NotificationId.DRIP_CHAMBER_DETACHED]) {
      const newNotification: TherapyJobNotification = {
        id: `${NotificationId.DRIP_CHAMBER_DETACHED}-${therapyJob.id}`,
        type: NotificationId.DRIP_CHAMBER_DETACHED,
        timestamp: therapyJob?.event?.timestamp,
        therapyJobId: therapyJob.id,
        translationKey: NotificationKey.DRIP_CHAMBER_DETACHED,
        hintKey: NotificationKey.DRIP_CHAMBER_DETACHED_HINT,
        level: NotificationLevel.ERROR,
        showAlarmCenter: true
      };

      this.notificationsSignal.update((prevValue) => {
        const tjNotifications = prevValue[therapyJob.id] || {};
        tjNotifications[NotificationId.DRIP_CHAMBER_DETACHED] = newNotification;

        return {
          ...prevValue,
          [therapyJob.id]: tjNotifications
        };
      });
    }
  }

  public getList(map): Array<TherapyJobNotification> {
    return Object.keys(map).reduce((acc, key) => {
      const tj = map[key];
      return acc.concat(
        Object.keys(tj).reduce((all, notificationKey) => {
          return all.concat(tj[notificationKey]);
        }, [])
      );
    }, []);
  }
}
