import { Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { Filter } from '../models/Filter';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private readonly filtersSignal: WritableSignal<Filter> = signal({ queries: [] });
  public filters: Signal<Filter> = this.filtersSignal.asReadonly();

  public setFilters(filter: Filter) {
    this.filtersSignal.set(filter);
  }
}
