import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CxBadgeNotification, CxButton, CxIcon } from '@bbraun/cortex-angular';
import { TranslateModule } from '@ngx-translate/core';
import { take } from 'rxjs';
import { TherapyJob } from '~/app/models/TherapyJob';
import { TherapyJobService } from '~/app/services/therapy-job.service';

@Component({
  selector: 'gplus-annotations',
  standalone: true,
  imports: [CxIcon, CxButton, FormsModule, MatFormFieldModule, CxBadgeNotification, TranslateModule, CommonModule],
  templateUrl: './annotations.component.html',
  styleUrl: './annotations.component.scss'
})
export class AnnotationsComponent {
  public model: string;
  public showIcon: boolean;

  public _therapyJob: TherapyJob;
  @Input() set therapyJob(tj: TherapyJob) {
    this._therapyJob = tj;
    this.model = tj.annotations;
    this.showIcon = !!tj.annotations;
  }

  @HostBinding('class.open')
  public _showAnnotations: boolean;
  @Input() set showAnnotations(value: boolean) {
    this._showAnnotations = value;
    setTimeout(() => {
      if (this._showAnnotations && this.textArea) {
        this.textArea.nativeElement.focus();
      }
    }, 100);
  }

  @Output() toggle: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('textArea') textArea: ElementRef;

  public isDirty: boolean;
  public submitLoader: boolean;

  constructor(private readonly therapyJobService: TherapyJobService) {}

  public save(event: MouseEvent) {
    event.stopPropagation();

    const newTherapyJob: TherapyJob = {
      ...this._therapyJob,
      annotations: this.model
    };

    this.submitLoader = true;
    this.therapyJobService
      .updateTherapyJob(newTherapyJob)
      .pipe(take(1))
      .subscribe({
        next: () => {
          setTimeout(() => {
            this.isDirty = false;
            this._showAnnotations = false;
            this.toggle.emit(false);
            this.submitLoader = false;
          }, 100);
        }
      });
  }

  public change() {
    this.isDirty = this.model !== this._therapyJob.annotations;
  }

  public cancel(event: MouseEvent) {
    event.stopPropagation();

    this.model = this._therapyJob.annotations;
    this.isDirty = false;
    this._showAnnotations = false;
    this.toggle.emit(false);
  }

  public toggleAnnotations(event: MouseEvent) {
    event.stopPropagation();

    this._showAnnotations = !this._showAnnotations;
    this.toggle.emit(this._showAnnotations);

    setTimeout(() => {
      if (this._showAnnotations && this.textArea) {
        this.textArea.nativeElement.focus();
      }
    }, 100);
  }
}
