@if(dataLoader){
<div class="loader-wrapper">
  <span class="loader absolute-centered"></span>
</div>
}@else {

<cx-search-bar
  class="search-bar"
  [placeholder]="'OnDemandTJCreationSearchPlaceholder' | translate"
  [matAutocomplete]="testAutocomplete"
  [value]="selectedValue.asObservable()"
  (search)="search($event)"
>
  <mat-autocomplete #testAutocomplete (optionSelected)="setSearchBarValue($event.option.viewValue)">
    <mat-option *ngFor="let value of filterValues">
      <span [innerHTML]="value.patient.lastName"></span>
    </mat-option>
    <mat-option *ngIf="!filterValues?.length" disabled>
      <span class="text-gray-120">{{ 'OnDemandTJCreationNoResults' | translate }}</span>
    </mat-option>
  </mat-autocomplete>
</cx-search-bar>

<cx-list [clickable]="true" class="mt-6">
  <cx-list-item
    *ngFor="let prescription of filterValues"
    (click)="select(prescription)"
    [active]="prescription.id === selected?.id"
    [itemTitle]="prescription.patient?.lastName + ', ' + prescription.patient?.firstName"
    [subtitle]="
      prescription.medication.medicines[0].display +
      ', ' +
      prescription.medication.medicines[0].amount +
      ' ' +
      prescription.medication.medicines[0].unit
    "
  >
    <div slot="right">
      {{ prescription.patient?.birthDate }}
    </div>
  </cx-list-item>
</cx-list>

<div slot="footer" class="footer">
  <cx-button variant="ghost" button-color="secondary" (click)="cancel()">
    {{ dialog?.config?.tertiaryLabel | translate }}
  </cx-button>
  <cx-button variant="outlined" button-color="secondary" (click)="modify()" [disabled]="!selected">
    {{ dialog?.config?.secondaryLabel | translate }}
  </cx-button>
  <cx-button variant="filled" (click)="submit()" [disabled]="!selected || submitLoader">
    {{ dialog?.config?.actionLabel | translate }}

    <span *ngIf="submitLoader" class="loader inline white"></span>
  </cx-button>
</div>
}
