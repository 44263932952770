import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, of, switchMap, take, tap, timeout } from 'rxjs';
import { BoardComponent } from '~/app/components/board/board.component';
import { TopBarComponent } from '~/app/components/top-bar/top-bar.component';
import { EnvironmentService } from '~/app/services/environment.service';
import { EventsService } from '~/app/services/events.service';
import { HeaderService } from '~/app/services/header.service';
import { MonitorDeviceService } from '~/app/services/monitor-device.service';

@Component({
  selector: 'gplus-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, TopBarComponent, BoardComponent, FormsModule]
})
export class HomeComponent {
  // panel for BE simulation
  public numberItems;
  public loadingSimulation: boolean;

  constructor(
    readonly monitorDeviceService: MonitorDeviceService,
    private readonly httpClient: HttpClient,
    private readonly env: EnvironmentService,
    private readonly eventsService: EventsService,
    private readonly translateService: TranslateService,
    private readonly route: ActivatedRoute,
    private readonly headerService: HeaderService
  ) {
    this.headerService.setHeader({
      titleKey: this.route.snapshot?.data?.titleKey,
      showBackBtn: false,
      showSearch: true
    });
  }
  public startSimulation() {
    let params = new HttpParams().set('numJobs', this.numberItems || 100);

    this.loadingSimulation = true;

    this.httpClient
      .get<any>(`${this.env.getBaseRestUrl()}/api/simulation/init`, { params: params })
      .pipe(
        timeout(1000),
        switchMap(() => {
          return this.httpClient.get<any>(`${this.env.getBaseRestUrl()}/api/simulation/start`);
        }),
        switchMap(() => {
          return this.eventsService.onTJStarts.pipe(
            take(1),
            tap((res) => {
              console.log('onTJStarts');
            })
          );
        }),
        catchError((err) => {
          console.warn('Error starting simulation', err);

          const snackbarConfig = {
            type: 'error',
            message: this.translateService.instant('SimulationHasError'),
            enableCloseButton: true,
            delay: 20000
          };
          window.dispatchEvent(new CustomEvent('cx-snackbar-open', { detail: snackbarConfig }));
          return of();
        })
      )
      .subscribe(() => {
        console.info('Simulation started');
        this.loadingSimulation = false;
      });
  }
}
