@if(!therapyJob?.totalVolume && therapyJob?.latestEvent?.injectedVolume){

<cx-badge-status class="cx-badge-status volume-infused" [status]="mostCriticalStatus || 'success'">
  {{ 'VolumeInfused' | translate }}: <b>{{ therapyJob?.latestEvent?.injectedVolume }}</b> {{ 'UnitMl' | translate }}
</cx-badge-status>

} @else {

<gp-progress-bar
  class="progress-bar {{ level }} {{ size }}"
  [value]="
    therapyJob?.latestEvent?.remainingTime
      ? (therapyJob?.latestEvent?.remainingTime | durationFormat : timeUnit.MINUTES)
      : null
  "
  [progress]="therapyJob?.latestEvent?.injectedVolume | progress : therapyJob?.totalVolume"
></gp-progress-bar>

}
