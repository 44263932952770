export enum NotificationKey {
  FLOW_RATE_TOO_HIGH = 'NotificationFlowRateTooHigh',
  FLOW_RATE_TOO_LOW = 'NotificationFlowRateTooLow',
  NO_FLOW_RATE_DETECTED = 'NotificationNoFlowRateDetected',
  OVERDUE = 'NotificationOverdue',
  PENDING_SIGNATURE = 'NotificationPendingSignature',
  THERAPY_JOB_ABORTED = 'NotificationTherapyJobAborted',
  THERAPY_JOB_UNPAIRED_FROM_MD = 'NotificationTherapyJobUnpairedFromMonitorDevice',
  THERAPY_JOB_UNPAIRED_FROM_MD_HINT = 'NotificationTherapyJobUnpairedFromMonitorDeviceHint',
  MAX_DAILY_DOSE_EXCEEDED = 'NotificationMaxDailyDoseExceeded',
  DRIP_CHAMBER_DETACHED = 'NotificationDripChamberDetached',
  DRIP_CHAMBER_DETACHED_HINT = 'NotificationDripChamberDetachedHint'
}
