import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject, Subject, catchError, debounceTime, map, of, take, tap } from 'rxjs';
import { TherapyJobStatus } from '../enums/TherapyJobStatus';
import { Filter } from '../models/Filter';
import { TherapyJob, TherapyJobExtended } from '../models/TherapyJob';
import { EnvironmentService } from './environment.service';
import { FilterService } from './filter.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class TherapyJobService {
  private therapyJobsSignal: WritableSignal<Array<TherapyJobExtended>> = signal([]);
  public therapyJobs: Signal<Array<TherapyJobExtended>> = this.therapyJobsSignal.asReadonly();
  public reload: Subject<string> = new ReplaySubject();

  public loadingTJs: boolean;

  constructor(
    private readonly httpClient: HttpClient,
    protected readonly sessionService: SessionService,
    private readonly translateService: TranslateService,
    private readonly env: EnvironmentService,
    private readonly filterService: FilterService
  ) {
    this.loadingTJs = true;

    this.reload.pipe(debounceTime(500)).subscribe((res) => {
      console.info('Reloading Therapy jobs because: ', res);
      this.loadingTJs = true;

      this.setTherapyJobs(this.filterService.filters()).subscribe(() => {
        this.loadingTJs = false;
      });
    });
  }

  private setTherapyJobs(filter: Filter): Observable<void> {
    return this.getTherapyJobs(this.sessionService.currentWard, filter).pipe(
      take(1),
      tap((therapyJobs) => {
        const sorted = [...therapyJobs].sort(
          (a, b) =>
            a.startTime?.planned - b.startTime?.planned || a.patient?.lastName.localeCompare(b.patient?.lastName)
        );

        // Update local state
        this.therapyJobsSignal.set(sorted);
      }),
      map(() => null)
    );
  }

  private getTherapyJobs(wardId: string, filter: Filter): Observable<Array<TherapyJob>> {
    let params = new HttpParams().set('ward', wardId);

    return this.httpClient
      .post<Array<TherapyJob>>(`${this.env.getBaseRestUrl()}/api/therapyjob/list`, null, { params: params })
      .pipe(
        catchError((err) => {
          console.warn('Error requesting therapy jobs', err);
          return of([]);
        }),
        map((therapyJobs) => {
          if (!filter.queries.length) {
            return therapyJobs;
          }

          return this.filter(filter, therapyJobs);
        })
      );
  }

  public patchTherapyJob(values: Partial<TherapyJobExtended>, therapyJobId: string) {
    this.therapyJobsSignal.update((tjs) => {
      return tjs.map((tj) => {
        if (tj.id === therapyJobId) {
          return {
            ...tj,
            ...values
          };
        }
        return tj;
      });
    });
  }

  public updateTherapyJob(therapyJob: TherapyJob): Observable<void | { error: string }> {
    const payload: { prepared?: boolean; annotation?: string } = {
      prepared: therapyJob.prepared,
      annotation: therapyJob.annotations || ''
    };
    return this.httpClient.patch<void>(`${this.env.getBaseRestUrl()}/api/therapyjob/${therapyJob.id}`, payload).pipe(
      tap(() => {
        this.reload.next('updateTherapyJob');
      }),
      catchError((err) => {
        console.warn('Error Updating Therapy Job', err);
        const snackbarConfig = {
          type: 'error',
          message: this.translateService.instant('ErrorUpdating'),
          enableCloseButton: true,
          delay: 20000
        };
        window.dispatchEvent(new CustomEvent('cx-snackbar-open', { detail: snackbarConfig }));
        this.reload.next('error.updateTherapyJob');
        return of({ error: 'Error Updating Therapy Job' });
      })
    );
  }

  public createTherapyJob(therapyJob: Partial<TherapyJob>): Observable<void> {
    return this.httpClient.post<void>(`${this.env.getBaseRestUrl()}/api/therapyjob`, therapyJob).pipe(
      catchError((err) => {
        console.warn('Error saving therapy jobs', err);
        const snackbarConfig = {
          type: 'error',
          message: this.translateService.instant('ErrorCreation'),
          enableCloseButton: true,
          delay: 20000
        };
        window.dispatchEvent(new CustomEvent('cx-snackbar-open', { detail: snackbarConfig }));
        return of();
      }),
      tap(() => {
        this.setTherapyJobs(this.filterService.filters())
          .pipe(take(1))
          .subscribe(() => {});
      })
    );
  }

  public getTherapyJob(id: string): Observable<TherapyJob> {
    return this.setTherapyJobs({ queries: [] }).pipe(
      map(() => {
        return this.therapyJobs().find((tj) => tj.id === id);
      })
    );
  }

  public getTherapyJobByMonitorDevice(id: string): TherapyJob {
    return this.therapyJobs().find((tj) => tj.monitorDeviceId === id);
  }

  /*
  
    Temporary filter FE solution
    Will be removed after BE integration
  */
  private filter(filter: Filter, therapyJobs: Array<TherapyJob>) {
    let filtered: Array<TherapyJob> = therapyJobs;
    filter.queries.forEach((f) => {
      switch (f.on) {
        case 'physician':
          filtered = filtered.filter((tj) => tj?.physician === f.bindValue);
          break;

        case 'medicine':
          filtered = filtered.filter(
            (tj) => tj?.medication?.medicines?.findIndex((m) => f.bindValue.includes(m.display)) !== -1
          );
          break;

        case 'patient':
          filtered = filtered.filter((tj) => f.bindValue.includes(tj?.patient?.id));
          break;

        case 'carrier':
          filtered = filtered.filter((tj) => tj?.medication?.carrier?.description === f.bindValue);
          break;

        case 'plannedTime':
          filtered = filtered.filter((tj) => this.matchTimePlanned(tj, f));

          break;

        case 'prepared':
          filtered = filtered.filter(
            (tj) => !!tj?.prepared === !!f.bindValue && tj?.status === TherapyJobStatus.PRESCRIBED
          );
          break;

        case 'room':
          filtered = filtered.filter((tj) => f.bindValue.includes(tj?.patient?.careUnit?.room));
          break;

        case 'search':
          const words = f.bindValue.trim().split(' ');

          filtered = filtered.filter((tj) => {
            const fullName = `${tj?.patient?.firstName} ${tj?.patient?.lastName}`;
            const isInTheName = words.every((w) => fullName?.toLowerCase().includes(w));

            return (
              tj?.medication?.medicines?.findIndex((m) => m.display.toLowerCase().includes(f.bindValue)) !== -1 ||
              isInTheName
            );
          });
          break;
      }
    });

    return filtered;
  }

  private matchTimePlanned(tj, f) {
    const start = f.value ? f.value.getTime() : null;
    const end = f.value ? f.value.getTime() + 1000 * 60 * 60 * 24 : null;

    if (start && end) {
      return tj?.startTime?.planned > start && tj?.startTime?.planned < end;
    }

    if (end) {
      return tj?.startTime?.planned < end;
    }

    if (start) {
      return tj?.startTime?.planned > start;
    }

    return true;
  }
}
