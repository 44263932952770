import { Injectable, WritableSignal, signal } from '@angular/core';
import { ModalOnDemandComponent } from '../components/modals/modal-on-demand/modal-on-demand.component';

export enum DialogIds {
  CREATE_ON_DEMAND = 'createOnDemand'
}

export interface Dialog {
  open: boolean;
  id: DialogIds;
  config: {
    title: string;
    actionLabel: string;
    secondaryLabel: string;
    tertiaryLabel: string;
  };
  component: any;
}

const dialogs: Array<Dialog> = [
  {
    open: false,
    id: DialogIds.CREATE_ON_DEMAND,
    config: {
      title: 'DialogOnDemandTitle',
      actionLabel: 'DialogOnDemandActionLabel',
      secondaryLabel: 'DialogOnDemandSecondaryLabel',
      tertiaryLabel: 'DialogOnDemandTertiaryLabel'
    },
    component: ModalOnDemandComponent
  }
];

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private dialogSignal: WritableSignal<Dialog> = signal(null);
  public dialog = this.dialogSignal.asReadonly();

  public open(id: DialogIds): void {
    const dialog = dialogs.find((d) => d.id === id);
    this.dialogSignal.update(() => ({ ...dialog, open: true }));
  }

  public close() {
    this.dialogSignal.update((current) => ({ ...current, open: false }));
  }
}
