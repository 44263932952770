import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { ReplaySubject, Subject } from 'rxjs';
import { NotificationLevel } from '../enums/NotificationLevel';
import { TherapyJobStatus } from '../enums/TherapyJobStatus';
import { TherapyJob } from '../models/TherapyJob';
import { DurationFormatPipe } from '../pipes/DurationFormat';
import { TJNotificationMap } from '../services/notification.service';
import { setTimeError } from './DelayTime';
import { getCriticalType } from './getCriticalNotificationType';

@Component({
  selector: 'gplus-component-alert',
  standalone: true,
  template: ''
})
export class ComponentAlert implements OnChanges {
  protected tjUpdate: Subject<{ firstChange: boolean }> = new ReplaySubject();

  @Input() therapyJob: TherapyJob;
  @Input() tjNotificationMap: TJNotificationMap;
  @Input() ticker: number;

  public mostCriticalStatus: NotificationLevel;
  public missingInfo: { tooltip: string; label: string };
  public timeError: string;

  constructor(protected readonly durationPipe: DurationFormatPipe, protected readonly translatePipe: TranslatePipe) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.therapyJob) {
      this.tjUpdate.next({ firstChange: changes.therapyJob.firstChange });
      this.setMissingInformationMsm(this.therapyJob);
    }

    if (changes.tjNotificationMap) {
      this.setNotificationCriticality();
    }

    if (changes.ticker) {
      this.setTimeError(this.therapyJob);
    }
  }

  private setNotificationCriticality() {
    //when we have multiple notifications we set the global criticality by the highest
    this.mostCriticalStatus = getCriticalType(this.tjNotificationMap);
  }

  private setMissingInformationMsm(therapyJob: TherapyJob): void {
    if (!therapyJob.flowRate.planned && !therapyJob.totalVolume) {
      this.missingInfo = {
        tooltip: 'TooltipInformationMissingFlowRateTotalVolume',
        label: 'NotificationInformationMissing'
      };
      return;
    }

    if (!therapyJob.flowRate.planned && therapyJob.totalVolume) {
      this.missingInfo = {
        tooltip: 'TooltipInformationMissingFlowRate',
        label: 'NotificationInformationMissing'
      };
      return;
    }

    if (therapyJob.flowRate.planned && !therapyJob.totalVolume) {
      this.missingInfo = {
        tooltip: 'TooltipInformationMissingTotalVolume',
        label: 'NotificationInformationMissing'
      };
      return;
    }

    this.missingInfo = null;
  }

  private setTimeError(therapyJob: TherapyJob): void {
    if (therapyJob.status === TherapyJobStatus.PRESCRIBED) {
      this.timeError = setTimeError(this.therapyJob.startTime.planned, this.durationPipe, this.translatePipe);
    } else {
      this.timeError = null;
    }
  }
}
