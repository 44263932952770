<gplus-filters></gplus-filters>

<!-- Connections status -->
<div class="system-status" [ngClass]="statusService.status?.id" *ngIf="statusService.status?.translationKey">
  {{ statusService.status.translationKey | translate }}
</div>

<cx-menu #cxMenu class="cx-menu">
  <cx-button
    [attr.data-vef]="'button-create-therapy'"
    slot="trigger"
    class="create-button"
    button-color="accent"
    type="button"
    as="button"
    variant="filled"
  >
    {{ 'AddJobButton' | translate }}
    <cx-icon class="cx-menu-trigger" icon="arrow_drop_down" filled="true"></cx-icon>
  </cx-button>

  <cx-menu-item menu-type="result" routerLink="/prn">
    <span>{{ 'AddJobOnDemand' | translate }}</span>
  </cx-menu-item>
  <cx-menu-item [attr.data-vef]="'button-create-therapy-option-manual'" menu-type="result" routerLink="/detail">
    <span>{{ 'AddJobManual' | translate }}</span>
  </cx-menu-item>
</cx-menu>
